import React, { useEffect, useState } from "react";
import { Modal, Input, Upload, Button, message, Select } from "antd";
import { capitalizeString } from "../../../reusable-func";


function extractFilename(url) {
  const match = url.match(/^https:\/\/[^?]+/);
  return match ? match[0] : null;
}
function ScoutingReportModal({
  onOpen,
  onCancel,
  title,
  gridironDetails,
  generateSignedUrl,
  school,
  coach_id,
  uploadScoutingReport,
  game
}) { 

  const [formValues, setFormValues] = useState({});
  const [uploadAction, setUploadAction] = useState(""); // Signed URL
  const [fileList, setFileList] = useState([]); // Manage file state
  const [fileUrl, setFileUrl] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const handleRequestSignedUrl = async (file) => {
    try {
      await generateSignedUrl({
        callback: (res, data) => {
          if (res) {
            const signedUrl = data.data.signedUrl;
            // message.success(
            //   "Signed URL generated successfully. Ready to upload."
            // ); 
            setUploadAction(signedUrl);
          }
        },
      });
    } catch (error) {
      console.error("Error generating signed URL:", error);
      message.error("Failed to generate signed URL.");
    }
  };

  useEffect(() => {
    handleRequestSignedUrl()
  }, [])

  const props = {
    name: 'file',
    action: uploadAction,
    method: 'PUT',

    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileUploadLoading(true);
        console.log(info.file, info.fileList);

      }
      if (info.file.status === 'done') {
        setFileUploadLoading(false);
        message.success(`${info.file.name} file uploaded successfully`);
        // setFileUrl(info)
      } else if (info.file.status === 'error') {
        setFileUploadLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleFormValuesUpdate = ({ target: { name, value } }) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleUploadScoutingReport = async () => {
    setFileUploadLoading(true);
    try {
      await uploadScoutingReport({
        body: {
          ...formValues,
          positions: formValues?.positions?.toString(),
          coach_id,
          school,
          game,
          url: extractFilename(uploadAction)
        },
        callback: (res, data) => {
          if (res) { 
            message.success("Report uploaded successfully");
            setFileUploadLoading(false);
            onCancel()
          }
          setFileUploadLoading(false); 
        },
      });
    } catch (error) {
      console.log("Error uploading scouting report:", error);
      setFileUploadLoading(false);
    }
  };
  return (
    <Modal
      open={onOpen}
      closable={true}
      onCancel={onCancel}
      title={
        <span className="text-[14px] font-inter font-bold text-[#0F1A24]">
          {title}
        </span>
      }
      footer={null}
      width={407}
    >
      <div className="flex flex-col font-inter  text-[14px] text-[#0F1A24] w-full mt-3">
        <div className="w-full flex flex-col gap-2 mb-4">
          <span className="font-inter text-[14px] font-bold text-[red]">
            *This feature is currently under construction.
          </span>
          <span className="font-inter text-[14px] font-normal">
            Which position group is this scouting report for?
          </span>
          <Select
            size="large"
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select Position(s)"
            className=" mt-[5px] text-[#0F1A24] w-full rounded-[4px]  "
            onChange={(value) =>
              handleFormValuesUpdate({
                target: {
                  name: "positions",
                  value,
                },
              })
            }
            optionLabelProp="label"
            options={[
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Defensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.defense[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Offensive unit
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.offense[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
              {
                label: (
                  <span className="font-inter text-[14px] font-bold text-[#0F1A24]">
                    Special Teams
                  </span>
                ),
                options:
                  gridironDetails?.player_positions?.special_teams[0]?.variables?.map(
                    ({ code, name }) => {
                      return {
                        label: (
                          <span className="font-inter text-[#0F1A24] text-[12px] font-medium">
                            <span className="font-bold">{code}</span>{" "}
                            {`(${capitalizeString(name)})`}
                          </span>
                        ),
                        value: code,
                      };
                    }
                  ) || [],
              },
            ]}
          />
        </div>
        <div className="flex items-center justify-between w-full mb-4">
          <Upload {...props}>
            <span className="text-[14px] font-inter py-2 px-3 bg-[#dddcdc] rounded-lg border-[1px] border-solid border-[#939191] font-bold text-[#0F1A24]">
              {" "}
              Choose PDF File
            </span>
          </Upload>
          <span>Select a file to upload</span>
        </div>
        <div className="mb-4 flex items-center justify-between">
          <div className="mr-3 font-bold w-[45%]">
            <span>Description:</span> <br />
            <span className="text-[#939191] text-[10px] font-[400] w-full">
              Max 25 characters
            </span>
          </div>
          <Input
            size="large"
            name="description"
            onChange={(e) =>
              handleFormValuesUpdate({
                target: { name: "description", value: e.target.value },
              })
            }
          />
        </div>
        <div className="w-full">
          <Button
            type="primary"
            htmlType="submit"
            loading={fileUploadLoading}
            disabled={fileUploadLoading}
            onClick={() => handleUploadScoutingReport()}
            className="w-full h-[43px] rounded-[4px] bg-[#1A91FF] border-1 border-solid border-[#1A91FF]"
          >
            <span className="text-[#FFFFFF]">Save</span>
          </Button>
        </div>
        <div
          className="w-full cursor-pointer border-[2px] border-solid border-[#A40E17] flex items-center justify-center h-[43px] rounded-[4px]"
          onClick={() => onCancel()}
        >
          <div className="flex items-center justify-between font-bold text-[14px] font-inter text-[#A40E17]">
            <i className="fas fa-xmark mr-[2%]"></i>
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ScoutingReportModal;
