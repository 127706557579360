import React, { useState } from "react";
import { Modal, Select, DatePicker, TimePicker, Space, Input } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import "./coaches.css";
import _ from "lodash";

const Option = Select.Option;

function EventModal({
  onOpen,
  onCancel,
  title,
  buttonText,
  handleUpdateEvent,
  game,
  schoolsList,
  loading,
}) {
  // const [timeValue, setTimeValue] = useState(moment(game?.start_time, "HH:mm"));
  const [timeValue, setTimeValue] = useState(
    moment(game?.start_time, "hh:mm A")
  );
  const [dateValue, setDateValue] = useState(moment(game?.start_date));
  const [formValues, setFormValues] = useState({});
  const [week, setWeek] = useState("");
  const [gameTitle, setGameTitle] = useState();
  const [weekList, setWeekList] = useState([
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
  ]);
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const handleFormValuesUpdate = ({ target: { name, value } }) => {
    const values = formValues;
    values[name] = value;
    setFormValues(values);
  };
  const handleUpdateEv = () => {
   
    const _date = dateValue;
    const _time = timeValue;
    handleUpdateEvent({
      ...formValues,
      week: week,
      end_time: _date,
      start_date: _date,
      start_time: _time,
    });
  };
  const onChange = (time, timeString) => {
    setTimeValue(time);
  };

  const handleDateChange = (date, dateString) => { 
    setDateValue(date);
  };
  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const onOpponentSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <Modal
      title={
        <span className="font-inter font-bold text-[#0F1A24] text-[14px]">
          {title}
        </span>
      }
      open={onOpen}
      width={450}
      style={{ top: 20 }}
      closable={true}
      onCancel={onCancel}
      footer={null}
    >
      <div className="flex flex-col font-inter text-[14px] text-[#0F1A24] mt-[10px] eventModal">
        {/* week */}
        {/* <span>
          Week <span className="text-[#ED780B]">*</span>
        </span>
        <input
          type="text"
          className="w-full h-[48px] border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px] p-[16px] mt-[8px]"
          placeholder="0"
        /> */}

        {/* Opponent name */}
        <span className="mt-[12px]">
          Opponent name <span className="text-[#ED780B]">*</span>
        </span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px] mt-[8px]">
          <Input
            defaultValue={game?.title}
            onChange={(e) => {
              handleFormValuesUpdate(e);
            }}
            name="title"
            style={{ width: 400, border: "none" }}
          />
        </div>

        {/* week */}
        <span className="mt-[12px]">
          Week <span className="text-[#ED780B]">*</span>
        </span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px] mt-[8px]">
          <Select
            style={{ width: "100%" }}
            showSearch={true}
            onSearch={onSearch}
            filterOption="children"
            bordered={false}
            onChange={(value) => setWeek(value)}
            defaultValue={game?.week}
          >
            {weekList.map((item, index) => {
              return (
                <Option key={index} value={_.get(item, "value")}>
                  {_.get(item, "name")}
                </Option>
              );
            })}
          </Select>
        </div>

        <span className="mt-[12px]">
          Event type <span className="text-[#ED780B]">*</span>
        </span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px]  mt-[8px]">
          <Select
            style={{ width: "100%" }}
            defaultValue={game?.type}
            dropdownMatchSelectWidth={true}
            bordered={false}
            onChange={(e) => {
              handleFormValuesUpdate({
                target: {
                  value: e,
                  name: "type",
                },
              });
            }}
          >
            {[
              { name: "Scrimmage", value: "scrimmage" },
              { name: "Practice", value: "practice" },
              { name: "Regular Season", value: "regular season" },
              { name: "Playoffs", value: "playoffs" },
            ].map((item, index) => {
              return (
                <Option key={index} value={item.value}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </div>

        {/* START DATE */}
        <span className="mt-[12px]">
          Start Date <span className="text-[#ED780B]">*</span>
        </span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px] mt-[8px]">
          <DatePicker
            bordered={false}
            style={{ width: "100%" }}
            // defaultValue={dateValue}
            // value={dateValue}
            placeholder={
              dateValue ? dateValue.format("YYYY-MM-DD") : "Select date"
            }
            onChange={(e) => handleDateChange(e)}
          />
        </div>

        {/* START TIME */}
        <span className="mt-[12px]">
          Start Time <span className="text-[#ED780B]">*</span>
        </span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px] mt-[8px]">
          <TimePicker
            bordered={false}
            style={{ width: "100%" }}
            // value={timeValue}
            format="hh:mm a"
            placeholder={timeValue.format("HH:mm a") || "Select time"}
            onChange={(e) => onChange(e)}
          />
        </div>
        <span className="mt-[16px] font-bold">Final Score</span>
        <div className="mt-[16px] flex items-center justify-between">
          <div className="w-[48%] flex flex-col">
            <span className="font-normal">Home Team Score</span>
            <input
              defaultValue={game?.home_team_score}
              type="number"
              name="home_team_score"
              onChange={(e) => handleFormValuesUpdate(e)}
              className="mt-[4px] w-full h-[47px] border-[1px] border-solid border-[#EAEDF0] outline-none px-[16px]"
              placeholder="0"
            />
          </div>
          <div className="w-[48%] flex flex-col">
            <span className="font-normal">Opponent Team Score</span>
            <input
              defaultValue={game?.away_team_score}
              type="number"
              name="away_team_score"
              onChange={(e) => handleFormValuesUpdate(e)}
              className="mt-[4px] w-full h-[47px] border-[1px] border-solid border-[#EAEDF0] outline-none px-[16px]"
              placeholder="0"
            />
          </div>
        </div>

        {/* EVALUATION TYPE */}
        <span className="mt-[12px]">Evaluation Type</span>
        <div className="w-full h-[48px] flex flex-col items-center justify-center border-[1px] border-solid bordeer-[#EAEDF0] rounded-[4px]  mt-[8px]">
          <Select
            onChange={(e) => {
              handleFormValuesUpdate({
                target: {
                  value: e,
                  name: "feedback_type",
                },
              });
            }}
            defaultValue={game?.feedback_type}
            style={{ width: "100%" }}
            dropdownMatchSelectWidth={true}
            bordered={false}
          >
            <Option value="analytical">Stat Mode</Option>
            <Option value="grading">Grade Mode</Option>
            <Option value="feedback">Feedback Only</Option>
          </Select>
        </div>
        <div className="flex self-end mt-[12px] w-[60%]">
          <button
            className="w-[48%] h-[43px] flex items-center justify-center mr-[8px] rounded-[4px] text-[#1A91FF] border-[2px] border-solid border-[#1A91FF] outline-none"
            onClick={() => onCancel()}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={() => handleUpdateEv()}
            disabled={loading}
            className="w-[48%] h-[43px] flex items-center justify-center rounded-[4px] bg-[#1A91FF] text-[#ffffff]"
          >
            {loading ? "loading..." : buttonText}
          </button>
          {/* [TODO: delete event] */}
        </div>
      </div>
    </Modal>
  );
}

export default EventModal;
