import React, { useState, useEffect } from "react";
import { Tooltip, Checkbox } from "antd";

const CheckboxGroup = Checkbox.Group;
export const CompletionsButtons = ({
    defaultState,
    handleQuickUpdate,
    title,
    item,
    img,
    tooltipTitle,
    tooltipTitleThree,
    tooltipTitleTwo,
    tooltipTitleFour,
    imageTwo,
    imageThree,
    imageFour,
    value,
  }) => {
    let parts = [];
    const options = [
      {
        label: (
          <Tooltip title={tooltipTitle}>
            <span className="text-[#A9B6C2] text-[12px] text-bold"> x </span>
          </Tooltip>
        ),
        value: 1,
      },
      {
        label: (
          <Tooltip title={tooltipTitleTwo}>
            <img src={imageFour} />
          </Tooltip>
        ),
        value: 2,
      },
      {
        label: (
          <Tooltip title={tooltipTitleThree}>
            <img src={imageTwo} />
          </Tooltip>
        ),
        value: 4,
      },
      {
        label: (
          <Tooltip title={tooltipTitleFour}>
            <span className="  text-[#A9B6C2] text-[12px] text-bold  "> 1 </span>
          </Tooltip>
        ),
        value: 8,
      },
    ];
  
    const [checkedValues, setCheckedValues] = useState(false);
    const onChange = (checkedValues) => {

      if (checkedValues.length == 1 && checkedValues[0] == 2) {
        checkedValues.push(1);
      }
      
      setCheckedValues(checkedValues);
      let value = 0;
      if (checkedValues.length) {
        value = checkedValues?.reduce((a, b) => a + b);
      } 
      
      handleQuickUpdate(item.id, item.athlete, title, value);
    };
  
    const handleButtonClick = (value) => {
      handleQuickUpdate(item.id, item.athlete, title, value);
    };
    useEffect(() => {
      processDefault();
    }, [defaultState]);
  
    const processDefault = () => {
      let _sum = defaultState;
  
      _sum = _valueBreakDown(_sum, 8);
      _sum = _valueBreakDown(_sum, 4);
      _sum = _valueBreakDown(_sum, 2);
      _sum = _valueBreakDown(_sum, 1);
      setCheckedValues(parts);
    };
  
    const _valueBreakDown = (_sum, part) => {
      if (_sum >= part) {
        parts.push(part);
        _sum = _sum - part;
      }
      return _sum;
    };
  
    return (
      <div className="discipline disciplinesradiodiv completion  other actor flex w-[100%] items-center justify-center ">
        <div className="button-group flex w-[100%] items-center justify-center ">
          <CheckboxGroup
            options={options}
            value={checkedValues}
            onChange={onChange}
          />
        </div>
      </div>
    );
  };