import {
  MoreOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import importImg from "../../../assets/img/evaluation-actions/import.svg";
import {
  Table,
  Tooltip,
  Avatar,
  Input,
  Radio,
  Form,
  Card,
  List,
  Skeleton,
} from "antd";
import shoe from "../../../assets/img/Shoes.svg";
import book from "../../../assets/img/open-book.svg";
import clock from "../../../assets/img/clock-outline.svg";
import { InputNumber, Checkbox, Switch } from "antd";
import React, { useEffect, useState, useReducer } from "react";
import FourwaySwitch from "../../../components/FourwaySwitch";
import eyeEmptyImg from "../../../assets/img/schedule/eye-empty.svg";
import undoCircleImg from "../../../assets/img/schedule/undo-circle.svg";
import chatImg from "../../../assets/img/chat-lines.svg";
import running from "../../../assets/img/running.svg";
import FeedbackModal from "./FeedbackModal";
import puzzle from "../../../assets/img/thumbs-down.svg";
import eye from "../../../assets/img/eye-alt.svg";
import consumable from "../../../assets/img/consumable.svg";
import check from "../../../assets/img/check.png";
import handBrake from "../../../assets/img/hand-brake.svg";
import maskSquare from "../../../assets/img/mask-square.png";
import whiteCheck from "../../../assets/img/Vectorcheckwhite.svg";
import { useNavigate } from "react-router-dom";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { Button, Modal } from "antd";
import stats from "../../../assets/img/add-hexagon.svg";
// import stats from "../../../assets/img/stats-square-up.svg";
import positiveImpact from "../../../assets/img/positiveImpact.svg";
import positiveImpactAction from "../../../assets/img/Action.svg";
import ModalModules from "./modals";
import arrowdown from "../../../assets/img/arrow-down.svg";
import football from "../../../assets/img/football-ball.svg";
import archery from "../../../assets/img/archery.svg";
import CustomSwitchButtons from "./CustomSwitchButtons";

const CheckboxGroup = Checkbox.Group;

const allowedPosition = {
  QB: "QB",
  OL: "OL",
  WR: "WR",
  TE: "TE",
  RB: "RB",
  OS: "OL",
  DL: "DL",
  LB: "LB",
  S: "S",
  C: "C",
  DB: "C",
  DF: "DL",
  IB: "LB",
  OB: "LB",
  K: "K",
  ST: "ST",
};

const TackleRadioButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  text,
  item,
  img,
  tooltipTitle,
  tooltipTitleThree,
  tooltipTitleTwo,
  imageTwo,
  imageThree,
  imageFour,
  value,
  color,
  colorThree,
  textTwo,
  textThree,
}) => {
  const handleButtonClick = (value) => {
    handleQuickUpdate(
      item.id,
      item.athlete,
      title,
      value,
      true,
      item.punt_distance || true
    );
  }; 
  return (
    <div className="discipline actor flex w-[100%] items-center justify-center ">
      <Radio.Group
        disabled={item.disabled}
        defaultValue={defaultState}
        onChange={({ target: { value: e } }) => {
          console.log("click on change", e);
        }}
        mode="tags"
      >
        <Tooltip title={tooltipTitle}>
          <Radio.Button
            value={0}
            onClick={() => {
              handleButtonClick(0);
            }}
            className={defaultState === 0 ? "x-active" : "x-inacive"}
          >
            {img ? <img src={img} /> : "x"}
          </Radio.Button>
        </Tooltip>
        <Tooltip title={tooltipTitleTwo}>
          {color ? (
            <Radio.Button
              onClick={() => {
                handleButtonClick(2);
              }}
              value={2}
              className={defaultState === 2 ? color : "onefive-inacive"}
            >
              {imageTwo && <img src={imageTwo} />}
              {textTwo && <span>{textTwo}</span>}
              {!imageTwo && !textTwo && "0.5"}
            </Radio.Button>
          ) : (
            <Radio.Button
              value={3}
              className={
                defaultState === 3 ? "onefive-active" : "onefive-inacive"
              }
              onClick={() => {
                handleButtonClick(3);
              }}
            >
              {imageTwo && <img src={imageTwo} />}
              {textTwo && <span>{textTwo}</span>}
              {!imageTwo && !textTwo && "0.5"}
            </Radio.Button>
          )}
        </Tooltip>
        {value ? (
          <Tooltip title={tooltipTitleThree}>
            <Radio.Button
              value={4}
              onClick={() => {
                handleButtonClick(4);
              }}
              className={
                defaultState === 4
                  ? colorThree
                    ? colorThree
                    : "one-active"
                  : "one-inacive"
              }
            >
              {imageThree ? <img src={imageThree} /> : value}
            </Radio.Button>
          </Tooltip>
        ) : (
          <Tooltip title={tooltipTitleThree}>
            <Radio.Button
              value={1}
              onClick={() => {
                handleButtonClick(1);
              }}
              className={defaultState === 1 ? "one-active" : "one-inacive"}
            >
              {imageThree && <img src={imageThree} />}
              {textThree && <span>{textThree}</span>}
              {!imageThree && !textThree && "1.0"}
            </Radio.Button>
          </Tooltip>
        )}
      </Radio.Group>
    </div>
  );
};
const DisciplineRadioButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  item,
  img,
  tooltipTitle,
  tooltipTitleThree,
  tooltipTitleTwo,
  tooltipTitleFour,
  imageTwo,
  imageThree,
  imageFour,
  value,
}) => {
  let parts = [];
  const options = [
    {
      label: (
        <Tooltip title={tooltipTitle}>
          <img src={img} />
        </Tooltip>
      ),
      value: 1,
    },
    {
      label: (
        <Tooltip title={tooltipTitleTwo}>
          <img src={imageTwo} />
        </Tooltip>
      ),
      value: 2,
    },
    {
      label: (
        <Tooltip title={tooltipTitleThree}>
          <img src={imageThree} />
        </Tooltip>
      ),
      value: 4,
    },
    {
      label: (
        <Tooltip title={tooltipTitleFour}>
          <img src={imageFour} />
        </Tooltip>
      ),
      value: 8,
    },
  ];

  const [checkedValues, setCheckedValues] = useState(false);
  const onChange = (checkedValues) => {
    setCheckedValues(checkedValues);
    let value = 0;
    if (checkedValues.length) {
      value = checkedValues?.reduce((a, b) => a + b);
    }
    handleQuickUpdate(item.id, item.athlete, title, value);
  };

  const handleButtonClick = (value) => {
    handleQuickUpdate(item.id, item.athlete, title, value);
  };
  useEffect(() => {
    processDefault();
  }, [defaultState]);

  const processDefault = () => {
    let _sum = defaultState;

    _sum = _valueBreakDown(_sum, 8);
    _sum = _valueBreakDown(_sum, 4);
    _sum = _valueBreakDown(_sum, 2);
    _sum = _valueBreakDown(_sum, 1);
    setCheckedValues(parts);
  };

  const _valueBreakDown = (_sum, part) => {
    if (_sum >= part) {
      parts.push(part);
      _sum = _sum - part;
    }
    return _sum;
  };

  return (
    <div className="discipline disciplinesradiodiv actor flex w-[100%] items-center justify-center ">
      <div className="button-group flex w-[100%] items-center justify-center ">
        <CheckboxGroup
          options={options}
          value={checkedValues}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const SackRadioButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  item,
  tooltipTitle,
  tooltipTitleTwo,
}) => {
  const handleButtonClick = (value) => {
    handleQuickUpdate(item.id, item.athlete, title, value);
  };

  return (
    <div className="discipline actor flex w-[100%] items-center justify-center ">
      <Radio.Group
        disabled={item.disabled}
        defaultValue={defaultState}
        onChange={({ target: { value: e } }) => {
          handleButtonClick(e);
        }}
      >
        <Tooltip title={tooltipTitle}>
          <Radio.Button
            value={0}
            className={
              defaultState === 0 ? "onefive-active" : "onefive-inacive"
            }
          >
            TFL
          </Radio.Button>
        </Tooltip>
        <Tooltip title={tooltipTitleTwo}>
          <Radio.Button
            value={2}
            className={
              defaultState === 2 ? "onefive-active" : "onefive-inacive"
            }
          >
            <img src={running} />
          </Radio.Button>
        </Tooltip>
        <Radio.Button
          value={1}
          className={defaultState === 1 ? "one-active" : "one-inacive"}
        >
          sack
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

const CarryRadioButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  tooltipTitle,
  item,
  img,
  color,
}) => {
  const handleButtonClick = (value) => {
    handleQuickUpdate(
      item.id,
      item.athlete,
      title,
      value,
      true,
      item.punt_distance || true
    );
  };
  return (
    <div className="discipline actor flex w-[100%] items-center justify-center ">
      <Radio.Group
        disabled={item.disabled}
        defaultValue={defaultState}
        onChange={({ target: { value: e } }) => {
          handleButtonClick(e);
        }}
      >
        <Tooltip title={tooltipTitle}>
          {color ? (
            <Radio.Button
              value={0}
              className={defaultState === 0 ? color : "x-inacive"}
            >
              {img ? <img src={img} /> : "x"}
            </Radio.Button>
          ) : (
            <Radio.Button
              value={2}
              className={defaultState === 2 ? "x-active" : "x-inacive"}
            >
              {img ? <img src={img} /> : "x"}
            </Radio.Button>
          )}
        </Tooltip>
        <Radio.Button
          value={1}
          className={defaultState === 1 ? "onefive-active" : "onefive-inacive"}
        >
          1
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

const EffortsSwitchButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  item,
}) => {
  const handleButtonClick = () => {
    handleQuickUpdate(
      item.id,
      item.athlete,
      title,
      Number(defaultState) === 1 ? 0 : 1
    );
  };

  return (
    <>
      {Number(defaultState) ? (
        <div
          id="execution"
          className="actor w-[100%] flex items-center justify-center"
        >
          <Switch
            disabled={item.disabled}
            checked={true}
            onChange={() => handleButtonClick()}
          />
        </div>
      ) : (
        <div id="execution" className="actor">
          <Switch
            disabled={item.disabled}
            checked={false}
            onChange={() => handleButtonClick()}
          />
        </div>
      )}
    </>
  );
};

const FlagSwitchButtons = ({
  defaultState,
  handleQuickUpdate,
  title,
  item,
}) => {
  const handleButtonClick = () => {
    handleQuickUpdate(
      item.id,
      item.athlete,
      title,
      Number(defaultState) === 1 ? 0 : 1
    );
  };

  return (
    <>
      {Number(defaultState) ? (
        <div className="flagged actor flex items-center w-[100%] justify-center">
          <Switch
            disabled={item.disabled}
            checked={true}
            onChange={() => handleButtonClick()}
          />
        </div>
      ) : (
        <div className="flagged actor flex w-[100%] items-center justify-center">
          <Switch
            disabled={item.disabled}
            checked={false}
            onChange={() => handleButtonClick()}
          />
        </div>
      )}
    </>
  );
};

const PuntDistance = ({
  item,
  handleQuickUpdate,
  setDistanceModal,
  distanceModal,
  defaultState,
}) => {
  const [distance, setDistance] = useState(defaultState);
  const [inside, setInside] = useState(item.punt_inside_20);
  const [fair, setFair] = useState(item.punt_fair_catch);
  const updateGrade = (path, value) => {
    handleQuickUpdate(item.id, item.athlete, path, value);
  };
  return (
    <>
      <Modal
        open={distanceModal}
        width={634}
        footer={null}
        closable={false}
        onCancel={() => {
          setDistanceModal(false);
        }}
        title={
          <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
            <span className=" font-bold">{distanceModal?.title}</span>
            <div className="w-[1px] h-[30px] bg-[#A9B6C2] mx-2"></div>
            <span className="font-[400]">
              {item?.first_name} {item?.last_name}
            </span>
          </div>
        }
      >
        <div className="flex flex-col w-full mt-4">
          <div
            style={{ border: "1px solid #F3F5F7)" }}
            className="w-full flex flex-col text-sm font-bold text-[#20364B]"
          >
            <div className="w-full flex">
              <div
                style={{ border: "1px solid #F3F5F7)" }}
                className="w-[25%] flex items-center p-3 justify-center bg-[#F3F5F7]"
              >
                <span>{distanceModal?.title}</span>
              </div>
              <div
                style={{ border: "1px solid #F3F5F7)" }}
                className="w-[20%] flex items-center p-3 justify-center turn-over actor"
              >
                <Input
                  defaultValue={distanceModal.default}
                  onChange={({ target: { value } }) => setDistance(value)}
                  className="w-[70%]"
                  size="large"
                />
              </div>
              {distanceModal?.hasOptions ? (
                <>
                  {" "}
                  <div
                    style={{ border: "1px solid #F3F5F7)" }}
                    className="w-[25%] flex items-center p-3 justify-center"
                  >
                    <Checkbox
                      defaultChecked={item.punt_inside_20}
                      onChange={({ target: { checked: e } }) =>
                        setInside(e ? 1 : 0)
                      }
                    >
                      <span className="text-[#2E4D6B]">Inside 20</span>
                    </Checkbox>
                  </div>
                  <div
                    style={{ border: "1px solid #F3F5F7)" }}
                    className="w-[25%] flex items-center p-3 justify-center"
                  >
                    <Checkbox
                      defaultChecked={item.punt_fair_catch}
                      onChange={({ target: { checked: e } }) =>
                        setFair(e ? 1 : 0)
                      }
                    >
                      <span className="text-[#2E4D6B]">Fair Catch</span>
                    </Checkbox>
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex items-center justify-between text-md mt-4  w-full flex-col">
            <button
              onClick={() => {
                handleQuickUpdate(
                  item.id,
                  item.athlete,
                  distanceModal.path,
                  distance
                );
                distanceModal?.hasOptions &&
                  handleQuickUpdate(
                    item.id,
                    item.athlete,
                    "punt_inside_20",
                    inside
                  );
                distanceModal?.hasOptions &&
                  handleQuickUpdate(
                    item.id,
                    item.athlete,
                    "punt_fair_catch",
                    fair
                  );
                setDistanceModal(false);
              }}
              className="flex items-center focus:outline-0 self-end justify-center w-[30%] bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]"
            >
              <span className="ml-2">Save</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const defaultModalReducerStates = {
  defensive: false,
  turnover: false,
  offensive: false,
  qbcarry: false,
  qbreception: false,
  qbcompletion: false,
  throw: false,
  target: false,
  kicker: false,
  special_teams: false,
  return: false,
  ol: false,
};

export function modalReducer(state, action) {
  const action_list = {
    "defensive-modal": {
      ...defaultModalReducerStates,
      defensive: true,
    },
    "turnover-modal": {
      ...defaultModalReducerStates,
      turnover: true,
    },
    "qbcarry-modal": {
      ...defaultModalReducerStates,
      qbcarry: true,
    },
    "qbreception-modal": {
      ...defaultModalReducerStates,
      qbreception: true,
    },
    "qbcompletion-modal": {
      ...defaultModalReducerStates,
      qbcompletion: true,
    },
    "throw-modal": { ...defaultModalReducerStates, throw: true },
    "target-modal": { ...defaultModalReducerStates, target: true },
    "kicker-modal": { ...defaultModalReducerStates, kicker: true },
    "special-teams-modal": {
      ...defaultModalReducerStates,
      special_teams: true,
    },
    "return-modal": { ...defaultModalReducerStates, return: true },
    "ol-modal": { ...defaultModalReducerStates, ol: true },

    "close-modal": defaultModalReducerStates,
  };

  const new_state = action_list[action.type];
  return new_state;
}

const Columns = (props) => {
  // const [feeback, setFeeback] = useState(false);
  // const [feebackItem, setFeebackItem] = useState(false);
  const [distanceModal, setDistanceModal] = useState(false);
  const [storeItem, setStoreItem] = useState(false);
  const [modalState, dispatchModalState] = useReducer(
    modalReducer,
    defaultModalReducerStates
  );

  const history = useNavigate();

  const [btnActive, setBtnActive] = useState(false);

  const handleBtnActive = () => {
    setBtnActive(!btnActive);
  };

  const PositiveFeedback = ({ text, item, athlete }) => {
    const [count, setCount] = useState(0);
    const [countSelf, setCountSelf] = useState(0);

    const handleUpdateCount = () => {
      // if(count <  2){
      setCountSelf(1);
      setCount(count + 1);
      // }
    };
    useEffect(() => {
      let initCount = 0;
      if (props.hasFeedback) {
        initCount = initCount + 1;
      }
      if (text?.length) {
        initCount = initCount + 1;
      }
      setCount(initCount);
    }, []);

    return (
      <>
        <div
          className={
            feedback_type == "analytical" ? "table-action flex" : "flex"
          }
        >
          <Tooltip title="Feedback">
            <div className="flex justify-around text-[12px] h-full items-center text-[#2E4D6B] ">
              {/* <Button
                  onClick={
                    () => {}
                    // history(`/manage-playlist?id=${_.get(item, 'game_id')}`)
                  }
                  icon={<img src={chatImg} className='w-[80%]' />}
                /> */}
              <img
                src={chatImg}
                className="w-[20px] cursor-pointer mr-[5px]"
                onClick={() => {
                  if (
                    feedback_type !== "analytical" &&
                    feedback_type !== "grading"
                  ) {
                    if (item?.disabled) {
                      props.handleCreateGrades(
                        athlete,
                        allowedPosition[props.data.code],
                        props.data?.id
                      );
                    } else {
                      handleToggleFeedback(item);
                      props.setFeebackItem(item);
                    }
                  } else {
                    if (item.disabled) {
                      return;
                    }
                    handleToggleFeedback(item);
                    props.setFeebackItem(item);
                  }
                }}
              />
              <span>{count + countSelf} </span>
            </div>
          </Tooltip>
        </div>
        {props.feeback && (
          <Modal
            title={
              <div className="flex font-inter text-[14px] text-[#0F1A24] items-center">
                <span className="font-bold mr-[8px]">Feedback to </span>
                <span className="font-normal">
                  {" "}
                  {props.feebackItem?.first_name} {props.feebackItem?.last_name}
                </span>
              </div>
            }
            width={525}
            open={props.feeback}
            onCancel={() => handleToggleFeedback(null)}
            closable={true}
            footer={null}
          >
            <FeedbackModal
              positive_feedback={props.feebackItem?.positive_feedback}
              groupFeedback={props.groupFeedback}
              sendFeedback={(detail) => {
                handleUpdateCount();
                props.handleQuickUpdate(
                  props.feebackItem?.id,
                  props.feebackItem?.athlete,
                  "positive_feedback",
                  detail
                );
                handleToggleFeedback(null);
              }}
            />
          </Modal>
        )}
      </>
    );
  };
  const columns = {};
  //playerListGrades
  //playerList
  const findRowData = (table_id, part) => {
    const item =
      props.playerListGrades?.find(({ athlete }) => athlete == table_id) || {};
    return item[part];
  };
  const preDefaults = [
    {
      title: () => {
        // return (<></>) ;
        return (
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "4.8%",
      render: (text, item) => {
        if (item.disabled)
          return (
            <>
              <Checkbox
                checked={false}
                onChange={() =>
                  props.handleCreateGrades(
                    text,
                    allowedPosition[props.data.code],
                    props.data?.id
                  )
                }
              ></Checkbox>
            </>
          );
        return (
          <>
            <Checkbox
              checked={true}
              onChange={() => props.handleRemoveGrades(item.id)}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: "Player Name",
      dataIndex: "id",
      align: "center",
      width: "13%",
      key: "player_name",
      render: (text, item) => {
        return (
          <div className="text-start ">
            {/* <Avatar
                        size={24}
        style={{
            fontSize: '12px',
             
          backgroundColor: '#fde3cf', color: '#f56a00'
        }}  
      >
      <p style={{
            position: 'relative',
    bottom: '2px'
      }}>{item.id[0].toUpperCase()}</p> 
      </Avatar> {' '} */}
            {item.first_name} {item.last_name}
          </div>
        );
      },
    },
    // {
    //   title: 'Position',
    //   dataIndex: 'positions',
    //   align: 'center',
    //   key: 'positions'
    // },

    {
      title: "Execution",
      dataIndex: "assignment_executed",
      align: "center",
      width: "8%",
      key: "assignment_executed",
      render: (text, item) => (
        <EffortsSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="assignment_executed"
        />
      ),
    },
    {
      title: "Effort",
      dataIndex: "great_effort",
      align: "center",
      width: "8%",
      key: "great_effort",
      render: (text, item) => (
        <EffortsSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="great_effort"
        />
      ),
    },
  ];

  const postDefaults = [
    {
      title: "Positive Impact",
      dataIndex: "positive_impact",
      width: "8%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div
            onClick={() => {
              if (item.disabled) return;
              props.handleQuickUpdate(
                item.id,
                item.athlete,
                "positive_impact",
                !text ? 1 : 0
              );
            }}
            className="flex w-[100%] items-center justify-center"
          >
            {text == 1 && (
              <img
                src={positiveImpactAction}
                className="w-[50%] cursor-pointer"
              />
            )}
            {(text === 0 || !text) && (
              <img src={positiveImpact} className="w-[50%] cursor-pointer" />
            )}
          </div>
        );
      },
    },
    {
      title: "Individualized Feedback",
      dataIndex: "positive_feedback",
      width: "8%",
      align: "center",
      key: "positive_feedback",
      render: (text, item) => <PositiveFeedback text={text} item={item} />,
    },
    {
      title: "Reset",
      dataIndex: "id",
      width: "6%",
      align: "center",
      className: "p-0",
      key: "action",
      render: (text, item) => {
        return (
          <div className="table-action mx-auto">
            {/* <Tooltip title="View Analytics">
              <Button
                onClick={() => {
                  history("/formation-analytic-dashboard", {
                    state: { position: item.position },
                  });
                }}
                icon={<img src={eyeEmptyImg} className="eye-img" />}
              />
            </Tooltip> */}

            <Tooltip title="Reset Eval">
              <Button
                onClick={() => {
                  if (item.disabled === true) return;
                  props.handleTrashGrades(
                    item.id,
                    item.athlete,
                    allowedPosition[props.data.code],
                    props.data?.id
                  );
                }}
                icon={<img src={undoCircleImg} />}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  columns.defensiveLine = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      align: "center",
      width: "8%",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Tackle",
      dataIndex: "tackles",
      width: "8.5%",
      align: "center",
      key: "tackle",
      render: (text, item) => (
        <TackleRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="tackles"
          tooltipTitle="Missed Tackle"
          textTwo="AST"
          tooltipTitleTwo="Assisted Tackle"
          textThree={1}
        />
      ),
    },
    {
      title: "Behind Line of Scrimmage",
      dataIndex: "sacks",
      width: "8.5%",
      align: "center",
      key: "sack",
      render: (text, item) => (
        <SackRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="sacks"
          tooltipTitle="Tackle for a Loss"
          tooltipTitleTwo="Pressure"
        />
      ),
    },

    {
      title: "Stat",
      dataIndex: "turnovers",
      width: "6%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "defensive-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[50%]"
            />
          </div>
        );
      },
    },
    ...postDefaults,
  ];

  columns.quarterback = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      width: "8%",
      align: "center",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Discipline",
      dataIndex: "missed_pat",
      align: "center",
      width: "8%",
      key: "discipline",
      render: (text, item) => (
        <DisciplineRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="missed_pat"
          img={shoe}
          imageTwo={eye}
          imageThree={book}
          imageFour={clock}
          tooltipTitle="Footwork"
          tooltipTitleTwo="Eyes"
          tooltipTitleThree="Read"
          tooltipTitleFour="Timing"
        />
      ),
    },

    {
      title: "Throw",
      dataIndex: "positive_impact",
      width: "8%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div className="flex w-[100%] items-center justify-center">
            <div
              className={`flex w-[30px] items-center justify-center rounded-full h-[30px] xl:w-[2.5vw] xl:h-[2.5vw] ${
                item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
              } ${
                item.receptions ||
                item.td ||
                item.yards_after_catch ||
                item.interception
                  ? "bg-[#BCECB5]"
                  : "bg-[#EAEDF0]"
              }`}
              onClick={() => {
                if (item.disabled === true) {
                  return;
                } else {
                  dispatchModalState({ type: "throw-modal" });
                  setStoreItem(item);
                }
              }}
            >
              <img src={football} className="w-[80%] cursor-pointer" />
            </div>
          </div>
        );
      },
    },
    {
      title: "Stat",
      dataIndex: "positive_impact",
      width: "8%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => { 
        return (
          <div className="flex w-[100%] items-center justify-center">
            <div
              className={`flex w-[30px] items-center justify-center rounded-full h-[30px] xl:w-[2.5vw] xl:h-[2.5vw] ${
                item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
              }  ${
                item.completions ||
                item.td ||
                item.yards_after_catch ||
                item.yards_after_contact ||
                item.missed_tackles ||
                item.caused_fumble ||
                item.total_carries ||
                item.fumble_lost
                  ? "bg-[#BCECB5]"
                  : "bg-[#EAEDF0]"
              }  `}
              onClick={() => {
                if (item.disabled === true) {
                  return;
                } else {
                  dispatchModalState({ type: "target-modal" });
                  setStoreItem(item);
                }
              }}
            >
              <img src={stats} className="w-[70%] cursor-pointer" />
            </div>
          </div>
        );
      },
    },
    ...postDefaults,
  ];
  columns.offensive = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      width: "8%",
      align: "center",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Carry",
      dataIndex: "total_carries",
      width: "8%",
      align: "center",
      key: "carry",
      render: (text, item) => (
        <CustomSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="total_carries"
        />
      ),
    },
    {
      title: "Reception",
      dataIndex: "receptions",
      width: "8%",
      align: "center",
      key: "reception",
      render: (text, item) => (
        <CarryRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={(a, b, c, d, e, f) => {
            dispatchModalState({ type: "qbreception-modal" });

            setStoreItem(item);
            props.handleQuickUpdate(a, b, c, d, e, f);
          }}
          title="receptions"
          img={handBrake}
          tooltipTitle="Drop"
        />
      ),
    },
    {
      title: "Pancake",
      dataIndex: "pancakes",
      width: "8%",
      align: "center",
      key: "pancakes",
      render: (text, item) => (
        <CustomSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="pancakes"
        />
      ),
    },
    ...postDefaults,
  ];
  const [showPassPro, setShowPassPro] = useState(true);
  const [modal, contextHolder] = Modal.useModal();

  columns.offensiveLinePass = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      align: "center",
      width: "8%",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Big Hit",
      dataIndex: "catchable_balls",
      width: "8%",
      align: "center",
      key: "big_hits",
      render: (text, item) => {
        return (
          <CustomSwitchButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={props.handleQuickUpdate}
            title="catchable_balls"
          />
        );
      },
    },
    {
      title: "Knockdown",
      dataIndex: "knockdowns",
      align: "center",
      key: "knockdowns",
      render: (text, item) => {
        return (
          <CustomSwitchButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={props.handleQuickUpdate}
            title="knockdowns"
          />
        );
      },
    },
    {
      title: "Pancake",
      dataIndex: "pancakes",
      width: "8%",
      align: "center",
      key: "pancakes",
      render: (text, item) => (
        <CustomSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="pancakes"
        />
      ),
    },
    {
      title: "Pass Protection",
      dataIndex: "pass_pro_sacks",
      align: "center",
      width: "8%",
      key: "pass_pro",
      render: (text, item) => {
        if (!showPassPro)
          return (
            <div className="actor flex w-[100%] items-center justify-center ">
              ----
            </div>
          );

        return (
          <TackleRadioButtons
            item={item}
            defaultState={text || 1}
            handleQuickUpdate={(a, b, c, d, e, f) => {
              if (d == 2) {
                setShowPassPro(false);
                const ids = [];
                props.playerList.map((grade) => {
                  if (
                    Object.keys(grade).includes("assignment_executed") &&
                    grade.pass_pro_sacks != 2
                  ) {
                    ids.push({ id: grade?.id, athlete: grade.athlete });
                  }
                });
                props.handleMultipleQuickUpdate(ids, c, d, e, f);
              } else {
                props.handleQuickUpdate(a, b, c, d, e, f);
              }
            }}
            title="pass_pro_sacks"
            imageTwo={consumable}
            imageThree={check}
            tooltipTitle="Lost"
            tooltipTitleTwo="RPO"
            text={text}
            tooltipTitleThree="Won"
            color="grey"
            colorThree="onefive-active"
          />
        );
      },
    },

    {
      title: "Stat",
      dataIndex: "turnovers",
      width: "6%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "ol-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[50%]"
            />
          </div>
        );
      },
    },
    ...postDefaults,
  ];
  columns.offensiveLine = [
    ...preDefaults,

    {
      title: "Flag",
      dataIndex: "penalties",
      align: "center",
      width: "8%",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Pancake",
      dataIndex: "pancakes",
      width: "8%",
      align: "center",
      key: "pancakes",
      render: (text, item) => (
        <CustomSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="pancakes"
        />
      ),
    },
    {
      title: "Pass Protection",
      dataIndex: "pass_pro_sacks",
      width: "8%",
      align: "center",
      key: "pass_pro",
      render: (text, item) => {
        if (
          props.formPlay?.play_type?.toLocaleLowerCase() == "run" ||
          !showPassPro
        )
          return (
            <div className="actor flex w-[100%] items-center justify-center ">
              ----
            </div>
          );

        return (
          <TackleRadioButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={(a, b, c, d, e, f) => {
              if (d == 2) {
                setShowPassPro(false);
                const ids = [];
                props.playerList.map((grade) => {
                  if (
                    Object.keys(grade).includes("assignment_executed") &&
                    grade.pass_pro_sacks != 2
                  ) {
                    ids.push({ id: grade?.id, athlete: grade.athlete });
                  }
                });

                props.handleMultipleQuickUpdate(ids, c, d, e, f);
              } else {
                props.handleQuickUpdate(a, b, c, d, e, f);
              }
            }}
            title="pass_pro_sacks"
            imageTwo={consumable}
            imageThree={check}
            tooltipTitle="Lost"
            tooltipTitleTwo="RPO"
            tooltipTitleThree="Won"
            color="grey"
            colorThree="onefive-active"
          />
        );
      },
    },

    {
      title: "Stat",
      dataIndex: "turnovers",
      width: "6%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "ol-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[50%]"
            />
          </div>
        );
      },
    },
    ...postDefaults,
  ];

  columns.offenseRB = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      width: "8%",
      align: "center",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Pass Protection",
      dataIndex: "pass_pro_sacks",
      align: "center",
      key: "pass_pro",
      width: "8%",
      render: (text, item) => {
        if (
          props.formPlay?.play_type?.toLocaleLowerCase() == "run" ||
          !showPassPro
        )
          return (
            <div className="actor flex w-[100%] items-center justify-center ">
              ----
            </div>
          );

        return (
          <TackleRadioButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={(a, b, c, d, e, f) => { 
              if (d == 2) {
                setShowPassPro(false);
                const ids = [];
                props.playerList.map((grade) => {
                  if (
                    Object.keys(grade).includes("assignment_executed") &&
                    grade.pass_pro_sacks != 2
                  ) {
                    ids.push({ id: grade?.id, athlete: grade.athlete });
                  }
                });

                props.handleMultipleQuickUpdate(ids, c, d, e, f);
              } else {
                props.handleQuickUpdate(a, b, c, d, e, f);
              }
            }}
            title="pass_pro_sacks"
            imageTwo={consumable}
            imageThree={check}
            tooltipTitle="Lost"
            tooltipTitleTwo="NA"
            tooltipTitleThree="Won"
            color="grey"
            colorThree="onefive-active"
          />
        );
      },
    },
    {
      title: "Stat",
      dataIndex: "positive_impact",
      width: "8%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div className="flex w-[100%] items-center justify-center">
            <div
              className={`flex w-[30px] items-center justify-center rounded-full h-[30px] xl:w-[2.5vw] xl:h-[2.5vw]   cursor-pointer ${
                item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
              } ${
                item.completions ||
                item.td ||
                item.yards_after_catch ||
                item.yards_after_contact ||
                item.missed_tackles ||
                item.caused_fumble ||
                item.total_carries
                  ? "bg-[#BCECB5]"
                  : "bg-[#EAEDF0]"
              }  `}
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "target-modal" });
                setStoreItem(item);
              }}
            >
              <img src={stats} className="w-[70%]" />
            </div>
          </div>
        );
      },
    },

    ...postDefaults,
  ];

  columns.offense = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      width: "8%",
      align: "center",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Pancake",
      dataIndex: "pancakes",
      width: "8.4%",
      align: "center",
      key: "pancakes",
      render: (text, item) => {
        return (
          <CustomSwitchButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={props.handleQuickUpdate}
            title="pancakes"
          />
        );
      },
    },
    {
      title: "Stat",
      dataIndex: "positive_impact",
      width: "8%",
      align: "center",
      key: "turn_over_created",
      render: (text, item) => {
        return (
          <div className="flex w-[100%] items-center justify-center">
            <div
              className={`flex w-[30px] items-center justify-center rounded-full h-[30px] xl:w-[2.5vw] xl:h-[2.5vw]   cursor-pointer ${
                item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
              } ${
                item.completions ||
                item.td ||
                item.yards_after_catch ||
                item.yards_after_contact ||
                item.missed_tackles ||
                item.caused_fumble ||
                item.total_carries
                  ? "bg-[#BCECB5]"
                  : "bg-[#EAEDF0]"
              }  `}
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "target-modal" });
                setStoreItem(item);
              }}
            >
              <img src={stats} className="w-[70%]" />
            </div>
          </div>
        );
      },
    },

    ...postDefaults,
  ];

  columns.kicker = [
    ...preDefaults,
    {
      title: "Flag",
      dataIndex: "penalties",
      align: "center",
      width: "8%",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "PAT",
      dataIndex: "pat",
      width: "8%",
      align: "center",
      key: "pat",
      render: (text, item) => (
        <TackleRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={(a, b, c, d, e, f) => {
            setStoreItem(item);
            e = false;
            props.handleQuickUpdate(a, b, c, d, e, f);
          }}
          title="pat"
          imageTwo={maskSquare}
          tooltipTitle="Missed"
          value="1"
          tooltipTitleTwo="Blocked"
          color="yellow"
          text={text}
        />
      ),
    },
    {
      title: "PUNT",
      dataIndex: "punts",
      align: "center",
      width: "8%",
      key: "punts",
      render: (text, item) => (
        <>
          <CarryRadioButtons
            item={item}
            defaultState={text}
            handleQuickUpdate={(a, b, c, d, e, f) => {
              if (d != 0) {
                e = true;
              } else {
                e = false;
              }
              setStoreItem(item);

              props.handleQuickUpdate(a, b, c, d, e, {
                default: item.punt_distance,
                path: "punt_distance",
                hasOptions: true,
                title: "Punt",
              });
            }}
            title="punts"
            img={maskSquare}
            tooltipTitle="Blocked"
            value="1"
            color="yellow"
          />

          <PuntDistance
            // updateProps ={}
            item={storeItem || item}
            distanceModal={props.distanceModal}
            handleQuickUpdate={props.handleQuickUpdate}
            setDistanceModal={props.setDistanceModal}
            defaultState={text}
          />
        </>
      ),
    },
    {
      title: "FG",
      dataIndex: "field_goals",
      align: "center",
      width: "8%",
      key: "field_goals",
      render: (text, item) => (
        <TackleRadioButtons
          item={item}
          defaultState={text}
          title="field_goals"
          imageTwo={maskSquare}
          text={text}
          tooltipTitle="Missed"
          tooltipTitleThree="Good"
          value="1"
          color="yellow"
          tooltipTitleTwo="Blocked"
          // handleQuickUpdate={props.handleQuickUpdate}
          handleQuickUpdate={(a, b, c, d, e, f) => { 

            let title;
            let defaultState;
            let path;
            e = true;

            if (d == 4) {
              title = "Field Goal Distance";
              defaultState = item.field_goal_distance;
              path = "field_goal_distance";
            } else if (d == 0) {
              title = "Missed Field Goal Distance";
              defaultState = item.missed_field_goal_distance;
              path = "missed_field_goal_distance";
            } else {
              e = false;
            }

            setStoreItem(item);

            props.handleQuickUpdate(a, b, c, d, e, {
              hasOptions: false,
              default: defaultState,
              title,
              path,
            });
          }}
        />
      ),
    },

    // {
    //   title: "Distance",
    //   dataIndex: "punt_distance",
    //   align: "center",
    //   key: "punt_distance",
    //   render: (text, item) => (
    //     <PuntDistance
    //       item={item}
    //       distanceModal={distanceModal}
    //       handleQuickUpdate={props.handleQuickUpdate}
    //       setDistanceModal={setDistanceModal}
    //       defaultState={text}
    //     />
    //   ),
    // },
    {
      title: "Kickoff",
      dataIndex: "td",
      align: "center",
      width: "8%",
      key: "td",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "kicker-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[40%]"
            />
          </div>
        );
      },
    },
    ...postDefaults,
  ];
  columns.specialTeams = [
    ...preDefaults,

    {
      title: "Flag",
      dataIndex: "penalties",
      align: "center",
      width: "8%",
      key: "flagged",
      render: (text, item) => (
        <FlagSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="penalties"
        />
      ),
    },
    {
      title: "Tackle",
      dataIndex: "tackles",
      width: "8.5%",
      align: "center",
      key: "tackle",
      render: (text, item) => (
        <TackleRadioButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="tackles"
          tooltipTitle="Missed Tackle"
          textTwo="AST"
          tooltipTitleTwo="Assisted Tackle"
          textThree={1}
        />
      ),
    },
    {
      title: "Unselfish Block",
      dataIndex: "catchable_balls",
      width: "8.4%",
      align: "center",
      key: "catchable_balls",
      render: (text, item) => (
        <CustomSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="catchable_balls"
        />
      ),
    },
    {
      title: "Return",
      dataIndex: "td",
      align: "center",
      width: "8%",
      key: "td",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "return-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[40%]"
            />
          </div>
        );
      },
    },
    {
      title: "Stat",
      dataIndex: "td",
      align: "center",
      width: "8%",
      key: "td",
      render: (text, item) => {
        return (
          <div
            className={`flex w-[100%] items-center justify-center ${
              item.disabled === true ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              onClick={() => {
                if (item.disabled === true) return;
                dispatchModalState({ type: "special-teams-modal" });
                setStoreItem(item);
              }}
              src={stats}
              className="w-[40%]"
            />
          </div>
        );
      },
    },

    ...postDefaults,
  ];

  columns.feedbackOnly = [
    {
      title: "Player Name",
      dataIndex: "id",
      align: "center",
      width: "13%",
      key: "player_name",
      render: (text, item) => {
        return (
          <div className="">
            {item.first_name} {item.last_name}
          </div>
        );
      },
    },
    {
      title: "Position",
      dataIndex: "position",
      align: "center",
      key: "position",
      width: "12%",
      render: (text, item) => {
        return <div className="">{props.data.code}</div>;
      },
    },

    {
      title: "Individualized Feedback",
      dataIndex: "positive_feedback",
      width: "35%",
      align: "left",
      key: "positive_feedback",
      render: (text, item) => (
        <PositiveFeedback text={text} item={item} athlete={item?.id} />
      ),
    },
    {
      title: ".",
      dataIndex: "positive_feedbacks",
      width: "40%",
      align: "left",
      key: "positive_feedbacks",
      render: (text, item) => (
        <div>
          {/* <input
            value={text}
            onFocus={() => {
              setFeebackItem(item);
            }}
            onChange={(e) => {
              const updatedValue = e.target.value;
              item.positive_feedbacks = updatedValue;
              setIndividualFeedback(updatedValue);
            }}
            placeholder="type individual feedback here"
            className="w-full p-1 focus:outline-none"
            // onMouseLeave={() => {
            //   // handleUpdateCount();
            //   props.handleQuickUpdate(
            //     feebackItem?.id,
            //     feebackItem?.athlete,
            //     "positive_feedback",
            //     individualFeedback
            //   );
            //   handleToggleFeedback(null);
            // }}
          /> */}
        </div>
      ),
    },
  ];
  columns.grading = [
    {
      title: () => {
        // return (<></>) ;
        return (
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
      dataIndex: "id",
      align: "center",
      key: "id",
      width: "4.8%",
      render: (text, item) => {
        if (item.disabled)
          return (
            <>
              <Checkbox
                checked={false}
                onChange={() =>
                  props.handleCreateGrades(
                    text,
                    allowedPosition[props.data.code],
                    props.data?.id
                  )
                }
              ></Checkbox>
            </>
          );
        return (
          <>
            <Checkbox
              checked={true}
              onChange={() => props.handleRemoveGrades(item.id)}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: "Player Name",
      dataIndex: "id",
      align: "center",
      width: "13%",
      key: "player_name",
      render: (text, item) => {
        return (
          <div className="">
            {item.first_name} {item.last_name}
          </div>
        );
      },
    },
    {
      title: "Execution",
      dataIndex: "assignment_executed",
      align: "center",
      width: "8%",
      key: "assignment_executed",
      render: (text, item) => (
        <EffortsSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="assignment_executed"
        />
      ),
    },
    {
      title: "Effort",
      dataIndex: "great_effort",
      align: "center",
      width: "8%",
      key: "great_effort",
      render: (text, item) => (
        <EffortsSwitchButtons
          item={item}
          defaultState={text}
          handleQuickUpdate={props.handleQuickUpdate}
          title="great_effort"
        />
      ),
    },
    {
      title: "Individualized Feedback",
      dataIndex: "positive_feedback",
      width: "35%",
      align: "left",
      key: "positive_feedback",
      render: (text, item) => (
        <PositiveFeedback text={text} item={item} athlete={item?.id} />
      ),
    },
    // {
    //   title: ".",
    //   dataIndex: "positive_feedbacks",
    //   width: "20%",
    //   align: "left",
    //   key: "positive_feedbacks",
    //   render: (text, item) => (
    //     <div>
    //       {/* <input
    //         value={text}
    //         onFocus={() => {
    //           setFeebackItem(item);
    //         }}
    //         onChange={(e) => {
    //           const updatedValue = e.target.value;
    //           item.positive_feedbacks = updatedValue;
    //           setIndividualFeedback(updatedValue);
    //         }}
    //         placeholder="type individual feedback here"
    //         className="w-full p-1 focus:outline-none"
    //         // onMouseLeave={() => {
    //         //   // handleUpdateCount();
    //         //   props.handleQuickUpdate(
    //         //     feebackItem?.id,
    //         //     feebackItem?.athlete,
    //         //     "positive_feedback",
    //         //     individualFeedback
    //         //   );
    //         //   handleToggleFeedback(null);
    //         // }}
    //       /> */}
    //     </div>
    //   ),
    // },
  ];

  const handleToggleFeedback = (item) => {
    props.setFeeback(!props.feeback);
    props.setFeebackItem(item);
  };
  const _cols = {
    DB: columns.defensiveLine,
    DL: columns.defensiveLine,
    LB: columns.defensiveLine,
    S: columns.defensiveLine,
    C: columns.defensiveLine,
    DB: columns.defensiveLine,
    DF: columns.defensiveLine,
    IB: columns.defensiveLine,
    OB: columns.defensiveLine,
    QB: columns.quarterback,
    TE: columns.offense,
    WR: columns.offense,
    RB: columns.offenseRB,
    OS: columns.offense,
    OL: columns.offensiveLine,
    OLP: columns.offensiveLinePass,

    K: columns.kicker,
    ST: columns.specialTeams,
    feedback: columns.feedbackOnly,
    grading: columns.grading,
  };

  useEffect(() => {
    setShowPassPro(true);
  }, [props.formPlay]);

  const confirm = (n) => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `This action will pre-select the Top ${n} players on your depth chart for the remainder of this game`,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        addMultipleGrades(n);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const addMultipleGrades = (n) => {
    const dataSource = props.playerList
      .sort((a, b) => a.rank - b.rank)
      .slice(0, n);

    const ids = dataSource
      .filter((i) => !Object.keys(i).includes("assignment_executed"))
      .map((i) => i.id);

    props.handleCreateMultipleGrades(
      ids,
      allowedPosition[props.data?.code],
      props.data?.id,
      props.data?.odk
    );
  };

  const paramsss = new URLSearchParams(window.location.search);
  let feedback_type = paramsss.get("t");

  const items = [
    {
      key: "1",
      label: <a onClick={() => confirm(1)}>Always select top 1 players</a>,
    },
    {
      key: "2",
      label: <a onClick={() => confirm(2)}>Always select top 2 players</a>,
    },
    {
      key: "3",
      label: <a onClick={() => confirm(3)}>Always select top 3 players</a>,
    },
    {
      key: "4",
      label: <a onClick={() => confirm(4)}>Always select top 4 players</a>,
    },
    {
      key: "5",
      label: <a onClick={() => confirm(5)}>Always select top 5 players</a>,
    },

    {
      key: "6",
      label: <a onClick={() => confirm(6)}>Always select top 6 players</a>,
    },
    {
      key: "8",
      label: <a onClick={() => confirm(8)}>Always select top 8 players</a>,
    },
    {
      key: "10",
      label: <a onClick={() => confirm(10)}>Always select top 10 players</a>,
    },
  ];

  return (
    <div className="eva-table">
      {contextHolder}
      <ModalModules
        state={modalState}
        school_id={props.school_id}
        loadAllPlayerList={props.loadAllPlayerList}
        item={storeItem}
        play={props.formPlay}
        getStats={props.getStats}
        handleUpdatePlay={props.handleUpdatePlay}
        update={props.handleQuickUpdate}
        dispatch={dispatchModalState}
        close={() =>
          dispatchModalState({
            type: "close-modal",
          })
        }
      />
      <Table
        bordered
        loading={props.playerListLoading}
        columns={
          feedback_type === "analytical"
            ? _cols[props.data.code] || []
            : feedback_type === "grading"
            ? _cols["grading"]
            : _cols["feedback"]
        }
        dataSource={props.playerList.sort((a, b) => a.rank - b.rank)}
        size="middle"
        pagination={false}
        rowClassName={(record, index) => {
          if (feedback_type === "analytical" && record.disabled) {
            return "row-disabled";
          } else if (feedback_type === "grading" && record.disabled) {
            return "row-disabled";
          }
          return "";
        }}
      />
    </div>
  );
};

export default Columns;
