import DefensiveModal from "./defensive-modal.js"; 
import TurnoverModal from "./turnover-modal";
import QbcarryModal from './qbcarry-modal.js'
import QbreceptionModal from './qbreception-modal.js'
import QbModal from './qb-completion.js';
import ThrowModal from "./throw-modal.js";
import TargetModal from "./target-modal.js";
import React, { useState, useEffect } from "react";
import PuntModal from "./punt-modal.js";
 
 
import KickerModal from "./kicker-modal.js";
import SpecialTeamsModal from "./special-teams-modal.js";
import ReturnModal from "./return-modal";
import OLModal from "./ol-modal.js";

 
const ModalModules = (props) => {
    const [customStats, setCustomStats] = useState([]);
    const { state, dispatch, close, update, item, school_id, loadAllPlayerList  } = props;
    const updateGrade = (path,value) => {

        update(
                      item.id,
                      item.athlete,
                      path,
                      value
             )
      }
      const updateInputGrade = (path, { target: { value }}) => { 
        updateGrade(path, Number(value))
      }

      useEffect(() => { 
        handleGetStats();
      }, []); 
      const handleGetStats = () => { 
        props.getStats({
          params: { school: school_id },
          callback: (res, responseData) => { 
            if (res) { 
              setCustomStats(responseData?.data);
            } else {
              console.log("Error retrieving stats:", responseData);
            } 
          },
        });
      };

    return (
        <> 

            <ReturnModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <SpecialTeamsModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <KickerModal    {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <DefensiveModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <TurnoverModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <QbcarryModal {...props}  updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <QbreceptionModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats}  />
            <QbModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <ThrowModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <TargetModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <PuntModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
            <OLModal {...props} updateInputGrade={updateInputGrade} updateGrade={updateGrade} schoolCustomStats={customStats} />
        </>
    )
}

export default ModalModules