import evalimg from "../../assets/img/clipboard-check.svg";
import analiticsimg from "../../assets/img/navbar/analitics.svg";
import list from "../../assets/img/list.svg";
import footballImg from "../../assets/img/football.svg";
import people from "../../assets/img/group.svg";
import dashbordIcon from "../../assets/img/dashboard-dots.svg";
import hat from "../../assets/img/hat.svg";
import coin from "../../assets/img/coin.svg";

export const routes = [
  {
    title: "Dashboard",
    url: "/coaches-evaluation",
    img: evalimg,
    activepaths: ["/season-plays-list", "/coaches-note", "/manage-playlist"],
    subRoutes: [],
    types: [0, 1],
  },
  {
    title: "Analytics",
    img: analiticsimg,
    subRoutes: [
      {
        title: "Season Analytics",
        url: "/season-analytic-dashboard",
      },
      {
        title: "Game Analytics",
        url: "/game-analytic-dashboard",
      },
      {
        title: "Self Scout Analytics",
        url: "/formation-analytic-dashboard",
      },
    ],
    types: [0, 1],
    isActive: false,
    setIsActive: () => {},
  },
  {
    title: "Performance",
    img: evalimg,
    subRoutes: [
      {
        title: "Season Stats",
        url: "/stats-view",
        activepaths: ["/stats-view", "/scoring"],
      },
      {
        title: "Player Stats",
        url: "/player-stats-view",
      },
      {
        title: "Leaderboard",
        url: "/leaderboard",
      },
    ],
    types: [0, 1],
    isActive: false,
    setIsActive: () => {},
  },
  {
    title: "My Depth Charts",
    url: "/athletes",
    img: list,
    activepaths: ["/athletes"],
    subRoutes: [],
    types: [0, 1],
  },
  {
    title: "Player List",
    url: "/player-list",
    img: footballImg,
    activepaths: ["/player-list", "/player-invite"],
    subRoutes: [],
    types: [0, 1],
  },
  {
    title: " Manage Coaches",
    url: "/coach-list",
    img: people,
    activepaths: ["/coach-list"],
    subRoutes: [],
    types: [1],
  },
];
export const adminRoute = [
  {
    title: "Dashboard",
    url: "/dashboard-view",
    img: dashbordIcon,
    activepaths: ["/dashboard-view"],
    subRoutes: [],
  },
  {
    title: "Teams",
    url: "/teams",
    img: people,
    activepaths: ["/teams"],
    subRoutes: [],
  },
  {
    title: "Coaches",
    url: "/coaches-view",
    img: hat,
    activepaths: ["/coaches-view"],
    subRoutes: [],
  },
  {
    title: "Plans",
    url: "/plans",
    img: coin,
    activepaths: ["/plans"],
    subRoutes: [],
  },
  {
    title: "Affiliate Referrals",
    url: "/affiliate-referrals",
    img: analiticsimg,
    activepaths: ["/affiliate-referrals"],
    subRoutes: [],
  },
];
