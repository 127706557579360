import React, { useState, useEffect } from "react";
import { Modal, Input, Switch, Select } from "antd";
import floppyDiskImg from "../../../../assets/img/floppy-disk.svg";
import Title from './components/title.js'
import { Link, useNavigate } from "react-router-dom";

function DefensiveModal({ state, dispatch, close, update, getStats, school_id,schoolCustomStats, item, updateGrade, updateInputGrade }) {
  let history = useNavigate();
  const [modalWidth, setModalWidth] = useState(425);
const [customStats, setCustomStats] = useState([]);
const [customStatValues, setCustomStatValues] = useState({});
const [ storage, setStorage ] = useState({})


useEffect(() => {
    
  setStorage(item)
  
}, [item]); 
const updateStorage = (path, value) =>{ 
  const store = storage;
  store[path] = value;

  setStorage(store)
  updateGrade(path, value)
}


  useEffect(() => {
    if (window.innerWidth <= 768) {
      setModalWidth(352);
    } else {
      setModalWidth(425);
    }
  }, [window.innerWidth]);


  const handleCStats = () => {
    setCustomStatValues(JSON.parse(item.custom_stats || '{}')) 
    const _customStats = schoolCustomStats?.filter(stat => stat.odk == 'd') 
    setCustomStats(_customStats || []);
  }
  useEffect(handleCStats, [item.custom_stats, schoolCustomStats]); 
  useEffect(handleCStats, []); 

   

  const handleSaveCustomStat = (stat, value) => {
      const _customStatValues = {...customStatValues}
      _customStatValues[stat.label] =  value;
      setCustomStatValues(_customStatValues)
    updateGrade('custom_stats', JSON.stringify(_customStatValues))
  }
  return state.defensive && (
    <Modal
      open={state.defensive}
      onOk={close}
      onCancel={close}
      closable={true}
      footer={null}
      width={modalWidth}
      title={<><Title title={'Defensive Stats'} player={`${item?.first_name} ${item?.last_name}`} /></>}
    >
      <>
        <div
          style={{ border: "1px solid #F3F5F7" }}
          className="mt-4 text-[12px] text-[#0F1A24]"
        >
             <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Caused Fumble</span>
          </div>{" "}
          <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
            <Switch defaultChecked={item.caused_fumble} onChange={(e) => updateGrade('caused_fumble', e ? 1 : 0)} />{" "}
          </div>
        </div>
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Fumble Recovered</span>
          </div>{" "}
          <div className="p-2 w-[30%] flex items-center justify-center">
            <div
              className="w-[100%] p-1 turn-over actor flex items-center justify-center rounded-[4px]"
               
            >
    <Switch defaultChecked={item.recovered_fumble} onChange={(e) => updateStorage('recovered_fumble', e ? 1 : 0)} />

            </div>
          </div>
        </div>

 
        { storage.recovered_fumble ? 
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Recovery</span>
          </div>{" "}
          <div className="p-2 px-0 w-[30%] turn-over actor flex items-center justify-center">
            <Input defaultValue={item.yards_after_recovery} onChange={(e)=>updateInputGrade('yards_after_recovery',e )} size="small" className="w-[40%] p-1" />{" "}
          </div>
        </div> : '' }



        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Interception</span>
          </div>{" "}
          <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
            <Switch defaultChecked={item.interception} onChange={(e) => updateStorage('interception', e ? 1 : 0)} />{" "}
          </div>
        </div>
 
        { storage.interception ? 
        <div
          className="flex items-center"
          style={{ borderBottom: "1px solid #F3F5F7" }}
        >
          <div
            className="w-[70%] p-3 font-bold"
            style={{ borderRight: "1px solid #F3F5F7" }}
          >
            <span>Yards After Interception</span>
          </div>{" "}
          <div className="p-2 px-0 w-[30%] turn-over actor flex items-center justify-center">
            <Input defaultValue={item.yards_after_interception} onChange={(e)=>updateInputGrade('yards_after_interception',e)} size="small" className="w-[40%] p-1" />{" "}
          </div>
        </div>
         : '' }

          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[70%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>PBU - Pass Breakup</span>
            </div>{" "}
            <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.pbu_pass_breakup}
                onChange={(e) => updateGrade("pbu_pass_breakup", e ? 1 : 0)}
              />{" "}
            </div>
          </div>
          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[70%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>Safety</span>
            </div>{" "}
            <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.safety}
                onChange={(e) => updateGrade("safety", e ? 1 : 0)}
              />{" "}
            </div>
          </div>
          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[70%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>Big Hit</span>
            </div>{" "}
            <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.big_hit}
                onChange={(e) => updateGrade("big_hit", e ? 1 : 0)}
              />{" "}
            </div>
          </div>
          <div
            className="flex items-center"
            style={{ borderBottom: "1px solid #F3F5F7" }}
          >
            <div
              className="w-[70%] p-3 font-bold"
              style={{ borderRight: "1px solid #F3F5F7" }}
            >
              <span>TD</span>
            </div>{" "}
            <div className="p-3 w-[30%] turn-over actor flex items-center justify-center">
              <Switch
                defaultChecked={item.td}
                onChange={(e) => updateGrade("td", e ? 1 : 0)}
              />{" "}
            </div>
          </div>
        </div>
        
          <div className="mt-4">
            <span className=" font-inter text-[14px] font-bold">
              Custom Defensive Stats{" "}
            </span>
            <div
              style={{ border: "1px solid #F3F5F7" }}
              className="mt-4 text-[12px] text-[#0F1A24] mb-2"
            >

              {
                  customStats?.map((stat, index) => {

                    return(
              <div
                className="flex items-center"
                style={{ borderBottom: "1px solid #F3F5F7" }}
              >
                <div
                  className="w-[70%] p-3 font-bold"
                  style={{ borderRight: "1px solid #F3F5F7" }}
                >
                  <span>{stat.label}</span>
                </div>{" "}
                <div className="p-3 w-[30%] turn-over actor flex items-center justify-center"> 
                 { stat.input_type == "numeric" ?
                 
   (<Input defaultValue={JSON.parse(item.custom_stats || '{}')[stat.label]} onChange={(e)=>handleSaveCustomStat(stat,e.target.value)} size="small" className="w-[40%] p-1" />)
   :
   (<Switch
    defaultChecked={JSON.parse(item.custom_stats || '{}')[stat.label]}
    onChange={(e) => handleSaveCustomStat(stat, e ? 1 : 0)}
  />)}
        
                </div>
              </div>
                    )

                  })
              }

             
            </div>
            <span className="text-[#8698A9]">
              Edit custom stats in your{" "}
              <span onClick={() => history("/add-stats")} className="underline">Admin Settings</span>
            </span>
          </div>
       
        <div className="flex items-center justify-between text-md mt-4">
          <button onClick={close} className="flex items-center focus:outline-0 justify-center w-full bg-[#1A91FF] text-[#ffffff] h-11 rounded-[4px]">
            <img src={floppyDiskImg} className="w-6" />
            <span className="ml-2">Save and close</span>
          </button>
        </div>
      </>
    </Modal>
  );
}

export default DefensiveModal;
