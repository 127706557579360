import React, { useState, useEffect } from "react";
import { Switch, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./modal.css";
const { Option } = Select;

const SwitchWithText = ({
  label,
  name,
  defaultColor,
  activeColor,
  isChecked,
  allAthletesList,
  handleCreateAnMvp,
  handleRemoveAnMvp,
  selectedValues: sv,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [formattedDefaultValue, setFormattedDefaultValue] = useState([]);

  const handleChange = (e) => { 
    const id = e[e.length - 1];
    if (Number(id)) {
      const player = allAthletesList.filter((player) => player.id == id);
      selectedValues.push(player[0]);
      setSelectedValues([...selectedValues]);

      handleCreateAnMvp(id, name);
    } else {
      
      const rmMvpName = findMissingElement(formattedDefaultValue, e);

      const rmMvp = selectedValues.find((obj) => {   
        return (
          `${obj.athlete.first_name + " " + obj.athlete.last_name}` == rmMvpName
        );
      });

        handleRemoveAnMvp(rmMvp.id);
      const __sv = selectedValues.filter((item) => item.id != rmMvp.id);
      setSelectedValues([...__sv]);
    }
  };
  function findMissingElement(A, B) {
    // Find the longer and shorter arrays
    const longerArray = A.length > B.length ? A : B;
    const shorterArray = A.length > B.length ? B : A;

    // Create a set of elements from the shorter array
    const elementSet = new Set(shorterArray);

    // Iterate through the longer array
    for (let i = 0; i < longerArray.length; i++) {
      // If the current element is not in the set, it is the missing element
      if (!elementSet.has(longerArray[i])) {
        return longerArray[i];
      }
    }

    // If no missing element is found, return null or any other appropriate value
    return null;
  }
  useEffect(() => { 
    const formattedDefaultValue = selectedValues?.map((obj) => {
      return `${obj.athlete.first_name + " " + obj.athlete.last_name}`;
    });
    setFormattedDefaultValue(formattedDefaultValue);
  }, [selectedValues]);
  useEffect(() => {
    setSelectedValues(sv);
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="text-md flex items-center mt-6 justify-between font-bold w-full">
        <span style={{ color: isChecked ? activeColor : defaultColor }}>
          {label}
        </span>
        {/* <Switch onChange={handleSwitchChange} /> */}
      </div>
      <div
        className="w-full flex items-center p-2 rounded-md mt-2"
        style={{ border: "1px solid #EaEDF0" }}
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          value={formattedDefaultValue}
          onChange={handleChange}
          bordered={false}
          showSearch
          filterOption={(inputValue, option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          }
          className="multi mr-2"
          optionLabelProp="label"
          placeholder={<span style={{ color: "#A9B6C2" }}>Add players</span>}
        >
          {allAthletesList?.map((player) => {
            return (
              <Option
                className="mt-2"
                value={player.id}
                label={`${player?.athlete?.first_name} ${player?.athlete?.last_name}`}
              >
                <div>
                  {player?.athlete?.first_name} {player?.athlete?.last_name} (
                  {player?.positions?.toString()})
                </div>
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default SwitchWithText;
